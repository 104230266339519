.headerNav {
  position: sticky;
  top: -60px;
  z-index: 1001;
  display: flex;
  gap: 40px;
  align-items: center;
  min-height: 60px;
  padding: 0 32px;
  background-color: rgba(247, 248, 250, 0.5);
  transition: 0.16s ease-in-out;

  @media screen and (max-width:1180px) {
    gap: 0;
  }


  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    backdrop-filter: blur(30px);
    content: '';
  }

  &.hasShadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0 0 16px;
  }

  &.isScrollDown {
    top: 0;
    transition: 0.16s ease-in-out;
  }
}

.mainContentSapce {
  flex: 1;
}

.navMainItem {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 160px;

  @media screen and (max-width:1180px) {
    position: relative;
    padding-left: 0;
    padding-right: 8px;
    flex: unset;

    &:hover {
      .navMainListBox {
        display: flex;
      }
    }
  }
}

.navLogoItem {
  position: relative;
  z-index: 101;

  :global {
    svg {
      width: 114px;
      height: 28px;
    }
  }
}

.navSideItem {
  position: relative;
  z-index: 101;

  :global {
    .ant-space {
      @media screen and (max-width:1180px) {
        gap: 16px !important;
      }
    }
  }
}

.navMainListBox {
  display: flex;
  gap: 100px;
  width: 100%;
  max-width: 1334px;

  @media screen and (max-width:1180px) {
    display: none;
    flex-direction: column;
    gap: unset;
    width: unset;
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(55, 55, 56, 0.08) 0 0 8px;
    padding: 8px 0;

  }

  :global {
    li {
      list-style: none;

      a {
        display: inline-flex;
        white-space: nowrap;

        @media screen and (max-width:1180px) {
          width: 100%;
          padding: 8px;
          text-align: center;
          font-size: 14px !important;
        }
      }

      a,
      span {
        color: #1d2129;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: #5764f1;
        }
      }

      .active {
        color: #5764f1;
      }
    }
  }
}

.navMainListRightBox {
  display: flex;
  width: 100%;
}

.switchChoice {
  z-index: 99999;
  width: 160px;
  // margin-top: 4px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 #0000001a;

  .typeItem {
    padding: 9px 4px;

    &:last-child {
      padding-top: 0;
    }
  }

  .typeTitle {
    padding: 0 9px;
    color: #86909c;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }

  // 头部
  .cn,
  .en {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 152px;
    height: 32px;
    padding-left: 7px;
    color: #1d2129;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    border-radius: 4px;
    font-weight: 400;

    .chooseIcon {
      position: relative;
      margin-right: 12px;
    }
  }

  .cn:hover,
  .en:hover {
    background: #f2f3f5 !important;
    color: #1d2129;
  }

  .location,
  .location:hover,
  .location:focus {
    font-weight: 500;
    font-size: 14px;
    color: #5764F1 !important;
    text-align: left;
    line-height: 22px;
  }

  .disable {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.actionAccount {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: -10px;
  padding: 8px;
  color: #1d2129;
  font-size: 0;
  white-space: nowrap;
  border-radius: 4px;

  .actionIcon {
    display: inline-block;
    // padding: 3px;
    vertical-align: top;

    +.account-text {
      margin-left: 4px;
    }
  }

  &:hover {
    background-color: #acbaf9;
  }
}

.navUser {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: -4px;
  padding: 8px;
  color: #1d2129;
  font-size: 0;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #acbaf9;
  }
}

.lgoDropdownBottom {
  border-radius: 8px;
}

:global {

  // 下拉框
  .ant-dropdown-menu {
    margin-top: 0 !important;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 #0000001a;
    padding: 4px;

    .ant-dropdown-menu-item-active {
      color: #1d2129 !important;
    }

    .ant-dropdown-menu-item {
      border-radius: 6px;
    }

    .dropdown-item-user {
      background: #fff;
    }

    .account-text2 {
      color: #666;
      font-weight: 400;
      font-size: 12px;
      white-space: nowrap;

      .account-line {
        display: block;
        height: 1px;
        margin: 8px 0 0;
        background: #e5e6eb;
      }
    }
  }
}

.navMoreIcon {
  display: none;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  @media screen and (max-width:1180px) {
    display: inline-flex;
  }

  &:hover {
    background-color: #acbaf9;
  }
}